import {NavigationService} from '../services/NavigationService';
import {NavigationStoreProps} from '../../types/app.types';
import {SiteStore} from '@wix/wixstores-client-storefront-sdk';
import {CheckoutService} from '../services/CheckoutService';
import {BIService} from '../services/BIService';
import {CheckoutOrigin} from '../utils/bi.util';
import {APP_DEFINITION_ID, STORES_APP_DEF_ID} from '@wix/wixstores-client-core';
import {SPECS} from '../../components/Checkout/constants';
import {isPickupFlow} from '../utils/isPickupFlow';
import {IWidgetControllerConfig} from '@wix/native-components-infra';

export class NavigationStore {
  private readonly biService: BIService;
  private readonly navigationService: NavigationService;
  private readonly siteStore: SiteStore;
  private readonly checkoutService: CheckoutService;
  private readonly controllerConfig: IWidgetControllerConfig;

  constructor({
    navigationService,
    siteStore,
    checkoutService,
    biService,
    controllerConfig,
  }: {
    navigationService: NavigationService;
    siteStore: SiteStore;
    checkoutService: CheckoutService;
    biService: BIService;
    controllerConfig: IWidgetControllerConfig;
  }) {
    this.navigationService = navigationService;
    this.siteStore = siteStore;
    this.checkoutService = checkoutService;
    this.biService = biService;
    this.controllerConfig = controllerConfig;
  }

  public navigateToOldCheckoutForUnsupportedFlow() {
    if (!this.isCurrentFlowSupported()) {
      this.biService.navigateToOldCheckout('not supported flow');
      this.navigationService.navigateToOldCheckout();
    }
  }

  public navigateToOldCheckoutForUnsupportedItems() {
    if (!this.areAllCheckoutItemsFromStores()) {
      this.biService.navigateToOldCheckout('non-stores', this.checkoutService.checkout.id);
      this.navigationService.navigateToOldCheckout();
    }
  }

  public async onLoadFailure() {
    this.biService.loadCheckoutFailed();
    /* istanbul ignore next */
    if (!this.navigationService.isLoaded) {
      await this.navigationService.load();
    }
    this.navigationService.navigateToContinueShopping();
  }

  private isCurrentFlowSupported() {
    const isFastFlow = Boolean(this.navigationService.appSectionParams.cashierPaymentId);

    const _isPickupFlow = isPickupFlow({
      navigationService: this.navigationService,
      checkoutService: this.checkoutService,
    });

    const isVisitorPickupFlow =
      _isPickupFlow &&
      !this.navigationService.isMember() &&
      this.siteStore.experiments.enabled(SPECS.UseNewCheckoutInVisitorPickup);
    const isMemberPickupFlow =
      _isPickupFlow &&
      this.navigationService.isMember() &&
      this.siteStore.experiments.enabled(SPECS.UseNewCheckoutInMemberPickupFlow);

    return isFastFlow || isVisitorPickupFlow || isMemberPickupFlow;
  }

  private areAllCheckoutItemsFromStores() {
    return (
      this.checkoutService.checkout.lineItems.filter(
        ({catalogAppId}) => ![APP_DEFINITION_ID, STORES_APP_DEF_ID].includes(catalogAppId)
      ).length === 0
    );
  }

  private readonly clickOnContinueShopping = (origin?: CheckoutOrigin) => {
    this.biService.clickOnContinueShopping(this.checkoutService.checkout, origin);
  };

  private readonly clickOnBackToSite = () => {
    this.biService.clickOnContinueShopping(this.checkoutService.checkout);
    return this.navigationService.navigateToContinueShopping();
  };

  private readonly clickOnReturnToCart = async () => {
    return this.navigationService.navigateToCart(this.checkoutService.checkout.cartId);
  };

  private readonly clickOnEditCart = () => {
    this.biService.clickOnEditCart(this.checkoutService.checkout);
  };

  private readonly navigateToThankYouPage = (orderId: string) => this.navigationService.navigateToThankYouPage(orderId);

  private readonly onLogin = (): void => {
    this.biService.checkoutClickLoginInCheckout(this.checkoutService.checkout);
    this.navigationService.navigateToOldCheckout();
  };

  public toProps(): NavigationStoreProps {
    return {
      clickOnBackToSite: this.clickOnBackToSite,
      clickOnContinueShopping: this.clickOnContinueShopping,
      clickOnReturnToCart: this.clickOnReturnToCart,
      clickOnEditCart: this.clickOnEditCart,
      continueShoppingUrl: this.navigationService.continueShoppingUrl,
      editCartUrl: this.navigationService.editCartUrl,
      isMember: this.navigationService.isMember(),
      navigateToThankYouPage: this.navigateToThankYouPage,
      trackEvent: (...args) => {
        this.controllerConfig.wixCodeApi.window.trackEvent(...args);
      },
      onLogin: this.onLogin,
    };
  }
}
